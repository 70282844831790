import React from 'react';
import styled from 'styled-components';
import { Heading } from '../../styles/Theme';
import { education } from '../../constants/Education';

const EduItem = styled.div`
  margin-bottom: 2rem;
`;

class Education extends React.Component {
  render() {
    return (
      <>
        <Heading>Training of the mind</Heading>
        {education.map((item, index) => {
          return (
            <EduItem className="row edu" key={index}>
              <div className="col-sm-12 col-md-8">
                <h3>
                  <strong>{item.label}</strong>
                </h3>
              </div>
              <div className="col-sm-12 col-md-4">
                <h5 className="float-sm-left float-md-right">{item.period}</h5>
              </div>
              <div className="col-sm-12">
                <h4>
                  {item.degree} <br />
                  {item.department}
                </h4>
              </div>
              <div className="col-sm-12">
                <p className="lead">{item.description}</p>
              </div>
            </EduItem>
          );
        })}
      </>
    );
  }
}

export default Education;
