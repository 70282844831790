import React from 'react';
import { quotes } from '../../constants/InspirationQuotes';

class Inspiration extends React.Component {
  render() {
    return (
      <div className="text-center">
        <h1 className="text-center">Quotes that Inspire Me</h1>
        {quotes.map((item, index) => {
          return <h5 key={index}>{item}</h5>;
        })}
      </div>
    );
  }
}

export default Inspiration;
