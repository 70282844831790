import React from 'react';
import Footer from '../components/footer';
import Navigation from '../components/navigation';
import Me from '../components/About-Me/Me';

export default class AboutMe extends React.Component {
  render() {
    return (
      <div className="about-me-page">
        <Navigation>
          <Me />
          <Footer />
        </Navigation>
      </div>
    );
  }
}
