export const education = [
  {
    label: 'University of Brighton',
    period: 'Sep 2018 - Oct 2019',
    degree: 'MSc in Data Analytics',
    department: 'School of Computing, Engineering and Mathematics',
    description:
      'The course structure covers three main areas: data management, data interpretation and project management. The former serves analysis purposes, including manipulating and structuring data. In the second area, data interpretation, during the course I used R, R Studio, SPSS and SAS for the statistical analysis in various projects. The latter area focuses on the business-specific aspects of analytics.',
  },
  {
    label: 'Aristotle University of Thessaloniki',
    period: 'Sep 2011 - Feb 2018',
    degree: 'Bachelors Degree in Mathematics',
    department: 'Faculty of Physics and Mathematics',
    description:
      'My BSc in Mathematics included modules divided into five departments: Computer Science and Numerical Analysis, Statistics &#38; Operational Reasearch Department, Mathematical Analysis, Geometry and Algebra Number Theory and Mathematical logic.',
  },
];
