import React from 'react';
import { webSkills, dataSkills } from '../../constants/Skills';
import {  Heading } from '../../styles/Theme';

class Skills extends React.Component {
  render() {
    return (
      <div className="skills-section">
        <Heading className="text-center">Never Stop Learning</Heading>
        <div className="row">
          <div className="col-sm-12 text-center">
            <h3 className="mb-4">Web Development</h3>
            <ul>
              <div className="row">
                {webSkills.map((item, index) => {
                  return (
                    <div key={index} className="col-sm-4">
                      <li>{item}</li>
                    </div>
                  );
                })}
              </div>
            </ul>
          </div>
          <div className="col-sm-12 text-center">
            <h3 className="mb-4">Data Analysis</h3>
            <ul>
              <div className="row">
                {dataSkills.map((item, index) => {
                  return (
                    <div key={index} className="col-sm-4">
                      <li>{item}</li>
                    </div>
                  );
                })}
              </div>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default Skills;
