export const workExperience = [
  {
    title: 'Software Engineer',
    period: 'Nov 2020-Present',
    company: 'Threads Styling, Ltd',
    description:
      'Threads Styling provides a personalised luxury shopping service. I joined as a software engineer and been working on a great range of products, mainly using TypeScript, React, React Native, NextJS, NodeJS, GraphQL, SQL, among others. I have an active role in technical planning, development and leading UAT sessions, communicating with different stakeholders and closely working with our UX team. Also been participating in the hiring process.',
  },
  {
    title: 'Software Engineer',
    period: 'Nov 2019-Nov 2020',
    company: 'Tivix, Inc',
    description:
      'Tivix is a software development agency/consultants working on a range of projects. Joined as a Software Engineer and currently working on a range of projects that are being built with Typescript, React, GraphQL, Material UI. Also working on the backend with TypeORM in Node. My day to day tasks/learnings include daily stand ups with the team, participation in project planning, sprint reviews and all ceremonies, close collaboration with UI/UX designers and project managers and use of tools like GitBook, ClubHouse, Invision',
  },
  {
    title: 'Front End Web Developer',
    period: 'Apr 2019-Nov 2019',
    company: 'Freelance',
    description:
      'I used JavaScript frameworks (React.JS) to build a new website, using CSS and Bootstrap for styling purposes. Also developed several web applications, utilising my skills in ReactJS and REST APIs. From this experience, I boosted my logic and problem-solving and managed to work under pressure and deadlines.',
  },
  {
    title: 'Private Tutor',
    period: 'Sep 2012-Sep 2018',
    company: 'Undergraduate Students',
    description:
      'I prepared students for school exams, planning their study schedules and whenever they have difficulties comprehend the tasks, point them to the right direction. This experience taught me being patient and adaptable with the students as well as evolve my interpersonal skills, since I had to communicate with the parents often enough to give them feedback of their child’s progress.',
  },
];
