export const webSkills = [
  'TypeScript',
  'JavaScript',
  'React.JS',
  'Node.JS',
  'Gatsby.JS',
  'Next.JS',
  'TypeORM',
  'HTML5',
  'CSS3',
  'Bootstrap 4',
  'Styled Components',
  'Material Design',
  'Git',
];

export const dataSkills = ['R', 'MySQL', 'Python', 'SAS', 'SPSS'];
