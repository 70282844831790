import React from 'react';
import styled from 'styled-components';
import { workExperience } from '../../constants/WorkExperience';
import {  Heading, TitleH3 } from '../../styles/Theme';

const WorkItem = styled.div`
  margin-bottom: 2rem;
`;

class Work extends React.Component {
  render() {
    return (
      <>
        <Heading>Dream hard, Work harder</Heading>

        {workExperience.map((item, index) => {
          return (
            <WorkItem className="row" key={index}>
              <div className="col-sm-12 col-md-8">
                <TitleH3>{item.title}</TitleH3>
              </div>
              <div className="col-sm-12 col-md-4">
                <h5 className="float-sm-left float-md-right">{item.period}</h5>
              </div>
              <div className="col-sm-12">
                <h4>{item.company}</h4>
              </div>
              <div className="col-sm-12">
                <p className="lead">{item.description}</p>
              </div>
            </WorkItem>
          );
        })}
      </>
    );
  }
}

export default Work;
