import React from 'react';
import {
  Container,
  SectionDescription,
  Heading,
  Paragraph,
} from '../../styles/Theme';
import Work from './Work';
import Education from './Education';
import Inspiration from './Inspiration';
import Skills from './Skills';
import { TabMenu } from './TabMenu';

const Me = () => {
  const [selectedTab, setSelectedTab] = React.useState('work');
  const renderSelectedTab = () => {
    switch (selectedTab) {
      case 'work':
        return <Work />;
      case 'education':
        return <Education />;
      case 'skills':
        return <Skills />;
      case 'inspiration':
        return <Inspiration />;
      default:
        return <Work />;
    }
  };
  return (
    <Container color="#000" style={{ minHeight: '80vh' }}>
      <Heading>About Me</Heading>
      <SectionDescription>
        <Paragraph>
          I am a Software Engineer from Greece, living in London.
          <br />I love getting my hands on new technologies and building
          wonderful and innovative websites and web applications.
        </Paragraph>
      </SectionDescription>
      <TabMenu setSelectedTab={setSelectedTab} selectedTab={selectedTab} />
      {renderSelectedTab()}
    </Container>
  );
};

export default Me;
