import React from 'react';
import styled from 'styled-components';

const Tabs = styled.div`
  display: flex;
  justify-content: center;
  margin: 3rem 10rem;
`;

const Tab = styled.h3`
  margin: 1rem;
  font-size: 20px;
  cursor: pointer;
  border-bottom-width: ${props => (props.selected ? '2px' : undefined)};
  border-bottom-style: ${props => (props.selected ? 'solid' : undefined)};
  border-bottom-color: ${props =>
    props.selected ? props.theme.main : undefined};
`;

export const TabMenu = ({ setSelectedTab, selectedTab }) => {
  return (
    <Tabs>
      <Tab
        selected={selectedTab === 'work'}
        onClick={() => setSelectedTab('work')}
      >
        Work
      </Tab>
      <Tab
        selected={selectedTab === 'education'}
        onClick={() => setSelectedTab('education')}
      >
        Education
      </Tab>
      <Tab
        selected={selectedTab === 'skills'}
        onClick={() => setSelectedTab('skills')}
      >
        Skills
      </Tab>
      <Tab
        selected={selectedTab === 'inspiration'}
        onClick={() => setSelectedTab('inspiration')}
      >
        Inspiration
      </Tab>
    </Tabs>
  );
};
